
import React from 'react';
import { LoginForm, Notification } from 'react-admin';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import logo from '../assets/logo.png';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'silver'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 400,
    maxWidth: '90%',
    padding: 20,
  },
  button: {
    margin: theme.spacing.unit,
  },
});

const HomePageButton = (props) => <Button {...props}>Вернуться на главную</Button>;

export default withStyles(styles)(class extends React.Component {
  constructor(props) {
    super(props);
    this.goHome = this.goHome.bind(this);
  }
  goHome(e) {
    e.preventDefault();
    window.location = '/'; // Because a push action dont set auth params to false and we see an auth tabs.
    //this.props.history.push('/');
  }
  render() {
    let { classes } = this.props;

    return (
        <MuiThemeProvider theme={this.props.theme}>
            <div className={classes.root}>
                <center><img src={logo} alt="Логотип" style={{width: '80%', marginBottom: 10}}/></center>
                <Card className={classes.card}>
                    <LoginForm />
                </Card>
                <HomePageButton className={classes.button} onClick={this.goHome}/>
                <Notification />
            </div>
        </MuiThemeProvider>
    );
  }
});