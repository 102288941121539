import React from 'react';
import { Title, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    padding: 10,
  },
  table: {
    width: "80%",
    fontSize: 24,
  },
};

const ContactsPage = ({ translate, classes, ...props }) => (
    <div className={classes.container}>
        <Title title={translate('staticPages.contacts.name')} />

        <center><table className={classes.table} border="1">
            <tbody>
                <tr>
                    <th colSpan="3">Телефоны<br />ТСЖ "Изумрудный"</th>
                </tr>
                <tr>
                    <th>Должность</th>
                    <th>Ф.И.О.</th>
                    <th>Телефон</th>
                </tr>
                <tr>
                    <td>Управляющий</td>
                    <td>Ковалев Николай Федорович</td>
                    <td>8-913-767-00-39</td>
                </tr>
                <tr>
                    <td>Начальник аварийно-диспетчерской службы</td>
                    <td>Почекунин Вячеслав Юрьевич</td>
                    <td>8-913-917-94-93</td>
                </tr>
                <tr>
                    <td>Бухгалтерия</td>
                    <td>Салагор Татьяна Алексеевна</td>
                    <td>8-38341-2-96-47</td>
                </tr>
                <tr>
                    <td>Юрист</td>
                    <td>Бурова Ольга Ивановна</td>
                    <td>8-38341-2-96-47</td>
                </tr>
                <tr>
                    <td>Электромонтер</td>
                    <td>Радушкин Владимир</td>
                    <td>8-913-005-25-30</td>
                </tr>
                <tr>
                    <td>Слесарь-сантехник</td>
                    <td>Черный Андрей Николаевич</td>
                    <td>8-953-767-47-19</td>
                </tr>
                <tr>
                    <td>Охрана КПП д.1</td>
                    <td></td>
                    <td>8-913-744-74-05</td>
                </tr>
                <tr>
                    <td>Охрана КПП д.13</td>
                    <td></td>
                    <td>8-913-756-39-98</td>
                </tr>
            </tbody>
        </table>
        <br /><br /> <b>Почта для связи:</b> tsg.izumrudnyi@yandex.ru</center>
    </div>
);

export default translate(withStyles(styles)(ContactsPage));