import React from 'react';
import { Title, translate } from 'react-admin';
import { FunctionField, DateField, EditButton, RichTextField, Edit, List, Datagrid, Filter, TextField, WithPermissions, Toolbar, SaveButton, SimpleForm, SelectInput, TextInput, LongTextInput, Create, showNotification, fetchUtils } from 'react-admin';
import { required, email } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { apiUrl } from '../config';
import checkPerm from '../permissions';

import PopularQuestionsIcon from '@material-ui/icons/HelpOutline';
import AddFeedbackIcon from '@material-ui/icons/PlusOne';
import ListFeedbackIcon from '@material-ui/icons/People';

const styles = {
  container: {
    padding: 10,
  },
  table: {
    width: "80%",
    fontSize: 24,
  },
};

const PopularQuestionsComponent = withStyles(styles)(class extends React.Component {
  render() {
    return (
      <div>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary" >Сайт находится в разработке?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="secondary" variant="subtitle2">
                Да, мы постоянно работаем над ним.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary" >Как можно получить доступ к личному кабинету на сайте?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="secondary" variant="subtitle2">
                Нужно обратиться в офис ТСЖ.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
      </div>
    )
  }
});

const AddFeedbackToolbar = ({...props}) => (
  <Toolbar {...props} >
      <SaveButton label="Отправить" />
      <p style={{ marginLeft: 20 }}>* Администраторы свяжутся с Вами по почте, как только обработают сообщение</p>
  </Toolbar>
)

const AddFeedbackComponent = connect(null, { showNotification })(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      categories: [],
      defaultCategory: null,
    };
  }
  componentDidMount() {
    let obj = this;
    // Get categories.
    fetchUtils.fetchJson(apiUrl + '/feedback/categories', {
      method: 'GET',
      credentials: 'include',
    }).then((res) => res.json).then(function(categories) {
      obj.setState({ defaultCategory: categories.default, categories: categories.data.map((category) => ({ id: category, name: `values.feedback.category.${category}` })) });
    }).catch((err) => { 
      obj.props.showNotification('Error: Categories are not loaded from server', 'warning')
    });
  }
  render() {
    const { location, match, userEmail } = this.props;
    return (
      <Create title=' ' undoable="false" resource="feedback" basePath="feedback" location={location} match={match}>
          <SimpleForm
              redirect="/feedback/faq"
              submitOnEnter={false} 
              toolbar={<AddFeedbackToolbar />}>
              <SelectInput source="category" choices={this.state.categories} validate={[required()]} defaultValue={this.state.defaultCategory}/>
              <TextInput defaultValue={userEmail} source="email" type="email" validate={[required(), email()]}/>
              <LongTextInput source="text" resettable validate={[required()]} />
          </SimpleForm>
      </Create>
    )
  }
});

const FeedbackFilter = connect(null, { showNotification })(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      categories: [],
      defaultCategory: null,
      statuses: [],
      defaultStatus: null,
    };
  }
  componentDidMount() {
    let obj = this;
    // Get categories.
    fetchUtils.fetchJson(apiUrl + '/feedback/categories', {
      method: 'GET',
      credentials: 'include',
    }).then((res) => res.json).then(function(categories) {
      obj.setState({ categories: categories.data.map((category) => ({ id: category, name: `values.feedback.category.${category}` })) });
    }).catch((err) => { 
      obj.props.showNotification('Error: Categories are not loaded from server', 'warning')
    });

    // Get statuses.
    fetchUtils.fetchJson(apiUrl + '/feedback/statuses', {
      method: 'GET',
      credentials: 'include',
    }).then((res) => res.json).then(function(statuses) {
      obj.setState({ statuses: statuses.data.map((status) => ({ id: status, name: `values.feedback.status.${status}` })) });
    }).catch((err) => { 
      obj.props.showNotification('Error: Statuses are not loaded from server', 'warning')
    });
  }
  render() {
    let withoutProps = Object.assign({}, this.props)
    delete withoutProps.showNotification;

    return (
      <Filter {...withoutProps}>
          <TextInput source="email" alwaysOn />
          <TextInput source="houseNumber" alwaysOn />
          <TextInput source="appartamentNumber" alwaysOn />
          <SelectInput source="category" choices={this.state.categories} defaultValue={this.state.defaultCategory} alwaysOn/>
          <SelectInput source="status" choices={this.state.statuses} defaultValue={this.state.defaultStatus} alwaysOn/>
      </Filter>
    )
  }
});

const ListFeedbackComponent = class extends React.Component {
  render() {
    const { translate, location, match } = this.props;
    return (
      <List 
        title=' '
        sort={{ field: 'createdAt', order: 'DESC' }}
        bulkActions={false} 
        filters={<FeedbackFilter />} 
        hasCreate={false} 
        hasEdit={false}
        hasList={false} 
        hasShow={false} 
        resource="feedback" 
        basePath="feedback" 
        location={location} 
        match={match}>
          <Datagrid>
              <DateField source="createdAt" showTime />
              <TextField source="email" />
              <TextField source="houseNumber" />
              <TextField source="appartamentNumber" />
              <FunctionField source="category" render={record => translate(`values.feedback.category.${record.category}`)} />
              <FunctionField source="status" render={record => translate(`values.feedback.status.${record.status}`)} />
              <EditButton basePath="/feedback" label="Обработать" />
          </Datagrid>
      </List>
    )
  }
};

const FeedbackEditToolbar = ({...props}) => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
)

export const FeedbackEdit =  translate(connect(null, { showNotification })(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      statuses: [],
      defaultStatus: null,
    };
  }
  componentDidMount() {
    let obj = this;
    // Get statuses.
    fetchUtils.fetchJson(apiUrl + '/feedback/statuses', {
      method: 'GET',
      credentials: 'include',
    }).then((res) => res.json).then(function(statuses) {
      obj.setState({ statuses: statuses.data.map((status) => ({ id: status, name: `values.feedback.status.${status}` })) });
    }).catch((err) => { 
      obj.props.showNotification('Error: Statuses are not loaded from server', 'warning')
    });
  }
  render() {
    const { translate } = this.props;
    let withoutProps = Object.assign({}, this.props)
    delete withoutProps.showNotification;

    return (
      <Edit title=' ' undoable={false} {...withoutProps} >
          <SimpleForm 
              submitOnEnter={false} 
              redirect='/feedback/moderate' 
              toolbar={<FeedbackEditToolbar />}>
              <DateField source="createdAt" showTime />
              <FunctionField source="category" render={record => translate(`values.feedback.category.${record.category}`)} />
              <TextField source="email" />
              <TextField source="houseNumber" />
              <TextField source="appartamentNumber" />
              <RichTextField source="text" style={{width: '100%'}} stripTags />

              <FunctionField source="moderateActions" style={{width: '100%'}} render={record => {
                let actions = record.moderateActions.map((action) => {
                  return (
                    <div key={action.id} >
                      <DateField showTime source="createdAt" record={action} />
                      <FunctionField 
                        render={() => `Пользователь ${action.author} изменил статус ${translate(`values.feedback.status.${action.prevStatus}`)} на ${translate(`values.feedback.status.${action.nextStatus}`)}`} 
                        />
                      <br />
                    </div>
                  )
                });
                return (
                  actions
                )
              }} />

              <SelectInput source="status" choices={this.state.statuses} validate={[required()]} defaultValue={this.state.defaultStatus}/>
          </SimpleForm>
      </Edit>
    )
  }
}));

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

const FeedbackPage = connect(null, { push })(class extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange = (event, value) => {
    let to;
    switch (value) {
      case 1:
        to = 'create';
        break;
      case 2:
        to = 'moderate';
        break;
      default:
        to = 'faq';
        break;
    }
    
    this.props.push(to) 
  };
  render() {
    const { translate, classes, location, permissions } = this.props;
    if (!permissions)
      return '';

    let indexPage;
    switch (this.props.page) {
      case 'create':
        indexPage = 1;
        break;
      case 'moderate':
        indexPage = 2;
        break;
      default:
        indexPage = 0;
        break;
    }

    return (
      <div className={classes.container}>
          <Title title={translate(`staticPages.feedback.${this.props.page}.name`)} />
              <Tabs value={indexPage} centered onChange={this.handleChange}>
                  <Tab disabled={indexPage === 0} selected={indexPage === 0} label="Популярные вопросы" icon={<PopularQuestionsIcon />} />
                  <Tab disabled={indexPage === 1} selected={indexPage === 1} label="Оставить обратную связь" icon={<AddFeedbackIcon />} />
                  {checkPerm('feedbackList', permissions) && <Tab disabled={indexPage === 2} selected={indexPage === 2} label="Работа с обратной связью" icon={<ListFeedbackIcon />} />}
              </Tabs>
              {// withpermission for reload page when cookie expired.
                indexPage === 0 && <TabContainer><WithPermissions location={location} render={() => <PopularQuestionsComponent />} /></TabContainer>}
              {indexPage === 1 && <TabContainer><WithPermissions location={location} render={() => <AddFeedbackComponent {...this.props} />} /></TabContainer>}
              {indexPage === 2 && checkPerm('feedbackList', permissions) && <TabContainer><WithPermissions location={location} render={() => <ListFeedbackComponent {...this.props} />} /></TabContainer>}
      </div>
    )
  }
})

export default translate(withStyles(styles)(FeedbackPage));