import React, { createElement } from 'react';
import { connect } from 'react-redux';
import { MenuItemLink, getResources, translate } from 'react-admin';
import { withRouter } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';

import AccountIcon from '@material-ui/icons/AccountCircle';
import PoolsIcon from '@material-ui/icons/ThumbsUpDownOutlined';
import WebcamsIcon from '@material-ui/icons/VideocamOutlined';
import ContactsIcon from '@material-ui/icons/MailOutline';
import ReceptionsIcon from '@material-ui/icons/Alarm';
import FeedbackIcon from '@material-ui/icons/SmsOutlined';
import OpenInformationsIcon from '@material-ui/icons/AttachFile';
import MetersIcon from '@material-ui/icons/CreateOutlined';
import TariffsIcon from '@material-ui/icons/WbIncandescentOutlined';

import logo from './assets/logo.png';

const MyMenu = ({ isLoggedIn, translate, resources, onMenuClick, logout, location, sidebarOpen }) => {
  return (
    <div>
        <center><img src={logo} alt="Логотип" style={{width: '80%', marginBottom: 10}}/></center>
        {resources.map(resource => (
            resource.hasList && resource.name !== 'payments' ? <MenuItemLink
              key={resource.name} 
              to={`/${resource.name}`} 
              primaryText={translate(`resources.${resource.name}.name`, {smart_count: 2})}
              onClick={onMenuClick} 
              leftIcon={createElement(resource.icon)}/> : ''
        ))}

        { isLoggedIn ? <MenuItemLink
            selected={location.pathname === "/profile" ? true : false}
            disabled={location.pathname === "/profile" ? true : false}
            to={`/profile`} 
            primaryText={translate("ra.auth.user_menu")} 
            onClick={onMenuClick} 
            leftIcon={createElement(AccountIcon)} /> : null }

        <MenuItemLink 
            to="/tariffs" 
            primaryText={translate("staticPages.tariffs.name")} 
            onClick={onMenuClick} 
            leftIcon={createElement(TariffsIcon)} />  
        <MenuItemLink 
            to="/contacts" 
            primaryText={translate("staticPages.contacts.name")} 
            onClick={onMenuClick} 
            leftIcon={createElement(ContactsIcon)} />
        <MenuItemLink 
            to="/receptions" 
            primaryText={translate("staticPages.receptions.name")} 
            onClick={onMenuClick} 
            leftIcon={createElement(ReceptionsIcon)} /> 
        { isLoggedIn ? <MenuItemLink 
            to="/feedback/faq" 
            selected={location.pathname.indexOf("/feedback/") === 0 ? true : false}
            disabled={location.pathname === "/feedback/faq" ? true : false}
            primaryText={translate("staticPages.feedback.faq.name")} 
            onClick={onMenuClick} 
            leftIcon={createElement(FeedbackIcon)} /> : null }
        { isLoggedIn ? logout : <MenuItemLink 
            to="/login" 
            primaryText={translate("ra.auth.sign_in")} 
            onClick={onMenuClick} 
            leftIcon={createElement(AccountIcon)} /> }

        {/* <Divider />
        { sidebarOpen && <ListSubheader>Страницы в разработке:</ListSubheader> }
        
        <MenuItemLink 
            to="/openinformations" 
            primaryText={translate("staticPages.openInformations.name")} 
            onClick={onMenuClick} 
            leftIcon={createElement(OpenInformationsIcon)} /> 
        <MenuItemLink 
            to="/webcams" 
            primaryText={translate("staticPages.webcams.name")} 
            onClick={onMenuClick} 
            leftIcon={createElement(WebcamsIcon)} /> 
        
        <MenuItemLink 
            to="/pools" 
            primaryText={translate("staticPages.pools.name")} 
            onClick={onMenuClick} 
            leftIcon={createElement(PoolsIcon)} />  */}
    </div>
  )
};

const mapStateToProps = state => ({
  sidebarOpen: state.admin.ui.sidebarOpen,
  isLoggedIn: state.admin.auth.isLoggedIn,
  resources: getResources(state),
});

export default translate(withRouter(connect(mapStateToProps)(MyMenu)));