import React from 'react';
import { withRouter } from 'react-router-dom';
import { Title, translate } from 'react-admin';

const InDevelopPage = ({ location, translate, ...props }) => (
    <div>
        <Title title={translate('staticPages.inDevelopPage.name')} />
    </div>
);

export default translate(withRouter(InDevelopPage));