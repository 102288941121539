import React from 'react';
import { Toolbar, ReferenceField, DateField, SaveButton, DeleteButton, DisabledInput, Filter, ArrayField, SingleFieldList, ChipField, ArrayInput, SimpleFormIterator, List, Datagrid, Edit, Create, SimpleForm, TextField, ShowButton, EditButton, TextInput } from 'react-admin';
import { fetchUtils, SelectArrayInput, Show, SimpleShowLayout, translate, showNotification } from 'react-admin';
import { connect } from 'react-redux';
import { required, email, maxLength, minLength } from 'react-admin';
import MaterialTextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/icons/CreateOutlined';
import checkPerm from '../permissions';

import { apiUrl } from '../config';

export const MeterIcon = Icon;

const MeterFilter = (props) => (
  <Filter {...props}>
      <TextInput source="meterId" alwaysOn />
      <TextInput source="account" alwaysOn />
  </Filter>
);

const exporter = () => {
  window.open(apiUrl + '/meters/getMetersData', '_blank');
}

export const MeterList = (props) => (
    <List bulkActions={!checkPerm('metersCreate', props.permissions) ? false : props.bulkActions} filters={<MeterFilter />} exporter={exporter} {...props}>
        <Datagrid>
            <TextField source="meterId" />
            <TextField source="account" />
            <TextField source="name" />
            <TextField source="lastValue" />
            <ShowButton label="Внести показания" basePath="/meters" />
            {checkPerm('metersEdit', props.permissions) && <EditButton basePath="/meters" />}
        </Datagrid>
    </List>
);

const MeterTitle = translate(({ record, translate }) => {
  return <span>{translate('resources.meters.name', { smart_count: 1 })}</span>;
});

export const MeterEdit = connect(null, { showNotification })(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      redirect: 'list'
    };
  }
  render() {
    let withoutProps = Object.assign({}, this.props)
    delete withoutProps.showNotification;

    return (
      <Edit title={<MeterTitle />} undoable={false} {...withoutProps} >
          <SimpleForm submitOnEnter={false} redirect={this.state.redirect}>
              <TextInput source="meterId" validate={[required()]}/>
              <TextInput source="account" validate={[required(), minLength(8), maxLength(8)]}/>
              <TextInput source="name" validate={[required()]}/>
              <TextInput source="lastValue"/>
          </SimpleForm>
      </Edit>
    )
  }
});

export const MeterCreate = connect(null, { showNotification })(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { roles: [] };
  }
  render() {
    let withoutProps = Object.assign({}, this.props)
    delete withoutProps.showNotification;

    return (
      <Create title="Создание счетчика" undoable="false" {...withoutProps}>
          <SimpleForm redirect="list">
              <TextInput source="meterId" validate={[required()]}/>
              <TextInput source="account" validate={[required(), minLength(8), maxLength(8)]}/>
              <TextInput source="name" validate={[required()]}/>
              <TextInput source="lastValue"/>
          </SimpleForm>
      </Create>
    )
  }
});

const AddMeterDataForm = connect(null, { showNotification })(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meterValue: '',
    };
  }
  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 30 }}>
          <MaterialTextField
            id="meterValue"
            style={{ flexBasis: 200 }}
            variant="outlined"
            label="Показания" 
            value={this.state.meterValue}
            onChange={(e) => this.setState({ meterValue: e.target.value })}
            placeholder={String(this.props.record.lastValue)}
          />
          <Button color="primary" onClick={() => {
            if (!this.state.meterValue || isNaN(this.state.meterValue))
              return this.props.showNotification('Your input value is not number', 'warning');

            fetchUtils.fetchJson(apiUrl + '/metersdata', {
              method: 'POST',
              body: JSON.stringify({ meter: String(this.props.record.id), value: Number(this.state.meterValue) }),
              credentials: 'include',
            }).then((res) => res.json).then(function() {
              document.location.reload(true);
            }).catch((err) => {
              if (err.status === 501)
                return this.props.showNotification('Error: bad params or frequently', 'warning');

              if (err.status === 400)
                return this.props.showNotification('Error: previous value can not be greater than this value', 'warning');

              if (err.status === 401)
                return document.location.reload(true);

              this.props.showNotification('Your metersData not accepted', 'warning');
            });
          }}>Отправить</Button>
      </div>
    )
  }
});

export const MeterShow = (props) => (
    <div>
      <Show title={<MeterTitle />} {...props}>
          <SimpleShowLayout>
              <TextField source="account" />
              <TextField source="meterId" />
              <TextField source="lastValue" />
              <AddMeterDataForm />
          </SimpleShowLayout>
      </Show>
      <List style={{ marginTop: 30 }} actions={null} filterDefaultValues={{ meter: props.id }} bulkActions={false} {...props} title=" " resource="metersData" sort={{ field: 'createdAt', order: 'DESC' }} >
          <Datagrid>
              <DateField source="createdAt" showTime />
              <TextField source="value" />
              <ReferenceField 
                  source="user" 
                  reference="users" 
                  linkType={false}>
                    <TextField source="fullName" />
              </ReferenceField>
          </Datagrid>
      </List>
    </div>
);