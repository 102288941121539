import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Labeled, BooleanInput,CreateButton, showNotification, Filter, ArrayField, SingleFieldList, ChipField, List, Edit, Create, SimpleForm, TextField, ShowButton, EditButton, TextInput } from 'react-admin';
import { addField, FunctionField, ReferenceField, DateField, RichTextField, ImageInput, ImageField, fetchUtils, SelectInput, Show, SimpleShowLayout, translate } from 'react-admin';
import { required, maxLength } from 'react-admin';
import ChipInput from 'material-ui-chip-input';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RichTextInput from 'ra-input-rich-text';

import ViewListIcon from '@material-ui/icons/ViewList';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import { apiUrl } from '../config';
import checkPerm from '../permissions';
import './../quillStyles.css';

export const PostIcon = ViewListIcon;

const toolbarOptions = [
  ['bold', 'italic', 'underline'],
  ['blockquote'],
  [{ 'header': 1 }, { 'header': 2 }],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  //[{ 'indent': '-1'}, { 'indent': '+1' }],
  [{ 'size': ['small', false, 'large', 'huge'] }],
  [{ 'color': [] }], 
  [{ 'align': [] }],
  //['image'],
  ['link'],
  ['clean'],
];

const stylesPostGrid = {
  card: {
    width: "100%",
    maxWidth: '480px',
    margin: '0.5em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: 'solid silver 1px',
  },
  media: {
    height: 200,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  cardNotPublish: {
    backgroundColor: 'silver'
  }
};

const PostsGrid = withStyles(stylesPostGrid)(({ ids, data, basePath, classes, permissions }) => {
  return (
    <div className={classes.root}>
        {ids.map(id =>
            <Card key={id} className={`${classes.card}${data[id].status === 'publish' ? '' : (' ' + classes.cardNotPublish)}`}>
                {data[id].frontImage ? <CardMedia
                  className={classes.media}
                  image={data[id].frontImage.src}
                /> : ''}
                <CardContent >
                    <Typography gutterBottom variant="h6" component="h6">
                        {data[id].title}
                    </Typography>
                    <Typography
                        gutterBottom 
                        component="p" 
                        noWrap={data[id].frontImage ? true : false}>
                        {data[id].teaser}
                    </Typography>
                    <Typography align="right" component="p" className={classes.pos} color="textSecondary">
                        № Дома: {data[id].houseNumber}
                    </Typography>
                    <ReferenceField 
                        source="author"
                        record={data[id]} 
                        basePath={basePath} 
                        reference="users" 
                        linkType={false}
                        variant="body2"
                        component="p" 
                        color="textSecondary" 
                        className={classes.pos} 
                        align="right">
                        <FunctionField render={record => record.fullName ? `Автор: ${record.fullName}` : ''} />
                    </ReferenceField>
                    {data[id].sticked && <Typography align="right" component="p" className={classes.pos} color="textSecondary">Новость закреплена</Typography>}
                </CardContent>
                <CardActions className={classes.actions}>
                    <ShowButton resource="posts" basePath={basePath} record={data[id]} />
                    { checkPerm('postEdit', permissions, data[id]) && <EditButton resource="posts" basePath={basePath} record={data[id]} />}
                </CardActions>
            </Card>
        )}
    </div>
  )
});

const PostFilter = (props) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
      <p style={{ marginRight: '30px', paddingTop: '8px', fontSize: 18, color: 'rgba(0, 0, 0, 0.7)' }}>Поиск:</p>
      <Filter {...props}>
          <TextInput source="title" alwaysOn />
          <TextInput source="houseNumber" alwaysOn />
      </Filter>
  </div>
);

const TagsChipField = ({ record = {} }) => <ChipField source="name" record={{ name: record }}/>;

const stylesPostList = {
  card: {
    backgroundColor: 'transparent!important',
    boxShadow: 'none'
  }
};

const ActionsToolbar = props => (
  <CardActions>
      { props.hasCreate && <CreateButton basePath={props.basePath} /> }
  </CardActions>
);

export const PostList = withStyles(stylesPostList)((props) => (
  <List actions={<ActionsToolbar/>} filters={<PostFilter />} sort={{ field: 'publishedAt', order: 'DESC' }} {...props}>
      <PostsGrid permissions={props.permissions} />
  </List>
));

const PostTitle = translate(({ record, translate }) => {
  return <span>{translate('resources.posts.name', { smart_count: 1 })} {record ? `${record.title}` : ''}</span>;
});

export const PostEdit = connect(null, { push, showNotification })(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultStatus: null,
      statuses: [],
      defaultAccessType: null,
      accessTypes: [],
    };
  }
  componentDidMount() {
    let obj = this;
    // Get statuses.
    fetchUtils.fetchJson(apiUrl + '/posts/statuses', {
      method: 'GET',
      credentials: 'include',
    }).then((res) => res.json).then(function(statuses) {
      obj.setState({ defaultStatus: statuses.default, statuses: statuses.data.map((status) => ({ id: status, name: `values.posts.status.${status}` })) });
    }).catch((err) => { 
      obj.props.showNotification('Error: Roles are not loaded from server', 'warning')
      obj.props.push('/posts') 
    });

    // Get accessTypes.
    fetchUtils.fetchJson(apiUrl + '/posts/accessTypes', {
      method: 'GET',
      credentials: 'include',
    }).then((res) => res.json).then(function(accessTypes) {
      obj.setState({ defaultAccessType: accessTypes.default, accessTypes: accessTypes.data.map((accessType) => ({ id: accessType, name: `values.posts.accessType.${accessType}` })) });
    }).catch((err) => { 
      obj.props.showNotification('Error: AccessTypes are not loaded from server', 'warning')
      obj.props.push('/posts') 
    });
  }
  render() {
    let withoutProps = Object.assign({}, this.props)
    delete withoutProps.push;
    delete withoutProps.showNotification;

    return (
      <Edit title={<PostTitle />} undoable={false} {...withoutProps}>
          <SimpleForm redirect="show">
              <TextInput source="title" validate={[required(), maxLength(75)]}/>
              <TextInput source="teaser" validate={[required(), maxLength(360)]}/>
              <BooleanInput source="sticked" />
              <ImageInput source="frontImage" accept="image/*" maxSize={5000000} placeholder="Загрузить файл (максимальный размер: 5mb)">
                  <ImageField source="src" title="title" />
              </ImageInput>
              <RichTextInput source="fullText" toolbar={toolbarOptions} validate={[required()]}/>
              <SelectInput source="status" choices={this.state.statuses} validate={[required()]} defaultValue={this.state.defaultStatus}/>
              <TagsChipInput source="tags" />
              <SelectInput source="accessType" choices={this.state.accessTypes} validate={[required()]} defaultValue={this.state.defaultAccessType}/>
          </SimpleForm>
      </Edit>
    )
  }
});

const TagsChipInput = addField(translate(({ translate, input, meta: { touched, error }, ...custom }) => (
  <MuiThemeProvider >
      <ChipInput
        hintText={translate(`resources.${custom.resource}.fields.${custom.source}`)}
        floatingLabelText={translate(`resources.${custom.resource}.fields.${custom.source}`)}
        errorText={touched && error}
        defaultValue={Array.isArray(input.value) ? input.value : []}
        onChange={input.onChange} />
  </MuiThemeProvider>
)));

export const PostCreate = connect(null, { push, showNotification })(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultStatus: null,
      statuses: [],
      defaultAccessType: null,
      accessTypes: [],
    };
  }
  componentDidMount() {
    let obj = this;
    // Get statuses.
    fetchUtils.fetchJson(apiUrl + '/posts/statuses', {
      method: 'GET',
      credentials: 'include',
    }).then((res) => res.json).then(function(statuses) {
      obj.setState({ defaultStatus: statuses.default, statuses: statuses.data.map((status) => ({ id: status, name: `values.posts.status.${status}` })) });
    }).catch((err) => { 
      obj.props.showNotification('Error: Roles are not loaded from server', 'warning')
      obj.props.push('/posts') 
    });

    // Get accessTypes.
    fetchUtils.fetchJson(apiUrl + '/posts/accessTypes', {
      method: 'GET',
      credentials: 'include',
    }).then((res) => res.json).then(function(accessTypes) {
      obj.setState({ defaultAccessType: accessTypes.default, accessTypes: accessTypes.data.map((accessType) => ({ id: accessType, name: `values.posts.accessType.${accessType}` })) });
    }).catch((err) => { 
      obj.props.showNotification('Error: AccessTypes are not loaded from server', 'warning')
      obj.props.push('/posts') 
    });
  }
  render() {
    let withoutProps = Object.assign({}, this.props)
    delete withoutProps.push;
    delete withoutProps.showNotification;

    return (
      <Create title="Создание новости" undoable="false" {...withoutProps}>
          <SimpleForm redirect="show">
              <TextInput source="title" validate={[required(), maxLength(75)]}/>
              <TextInput source="teaser" validate={[required(), maxLength(360)]}/>
              <BooleanInput source="sticked" />
              <ImageInput source="frontImage" accept="image/*" maxSize={5000000} placeholder="Загрузить файл (максимальный размер: 5mb)">
                  <ImageField source="src" title="title" />
              </ImageInput>
              <RichTextInput source="fullText" toolbar={toolbarOptions} validate={[required()]}/>
              <SelectInput source="status" choices={this.state.statuses} validate={[required()]} defaultValue={this.state.defaultStatus}/>
              <TagsChipInput source="tags" />
              <SelectInput source="accessType" choices={this.state.accessTypes} validate={[required()]} defaultValue={this.state.defaultAccessType}/>
          </SimpleForm>
      </Create>
    )
  }
});

const PostShowTitle = ({ record, ...props }) => (
    <Typography variant="h2" gutterBottom={true} style={{marginTop: 20}}>{record.title}</Typography>
);
const PostShowTeaser = ({ record, ...props }) => (
    <Typography variant="h5" gutterBottom={true}>{record.teaser}</Typography>
);
const PostShowPublishedAt = ({ record, ...props }) => (
    record.publishedAt ? 
        <Typography align="right">
            <DateField record={record} source="publishedAt" showTime />
        </Typography> : ''
);
const PostShowTags = translate(({ record, translate, ...props }) => (
  record.tags && record.tags.length ? 
    <Labeled label={translate('resources.posts.fields.tags')}>
      <ArrayField source="tags" record={record}>
        <SingleFieldList linkType={false}>
            <TagsChipField />
        </SingleFieldList>
      </ArrayField>
    </Labeled> : ''
));


export const PostShow = (props) => (
    <Show  title={<PostTitle />} {...props}>
        <SimpleShowLayout>
            <PostShowTitle />
            <PostShowTeaser />
            <ReferenceField 
              source="author" 
              reference="users" 
              linkType={false}>
                <TextField source="fullName" />
            </ReferenceField>
            <ImageField source="frontImage.src" style={{width: "100%"}} classes={{ image: "posts-show-frontImage" }} />
            <PostShowTags />
            <RichTextField source="fullText" addLabel={false} className="ql-editor" />
            <PostShowPublishedAt />
        </SimpleShowLayout>
    </Show>
);
