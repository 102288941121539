import React from 'react';
import { Filter, TextInput, DateInput, SimpleForm, ReferenceField, FileInput, FileField, DateField, Create, List, Datagrid, TextField, ShowButton} from 'react-admin';
import { showNotification, Show, SimpleShowLayout, translate } from 'react-admin';
import { required } from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MaterialTextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import refundApplication from '../assets/refund_application.doc';
import { connect } from 'react-redux';
import bankPng from '../assets/bank.png';
import { getDebtAtEndOfPeriod, getAccountFromXlsxFileName } from '../actions';
import checkPerm from '../permissions';

export const InvoiceIcon = PaymentIcon;

const InvoiceFilter = (props) => (
  <Filter {...props}>
      <TextInput source="account" alwaysOn />
  </Filter>
);

export const InvoiceList = (props) => (
  <List bulkActions={!checkPerm('invoicesCreate', props.permissions) ? false : props.bulkActions} {...props} filters={<InvoiceFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
      <Datagrid>
          <TextField source="account" />
          <DateField source="dateFrom" />
          <DateField source="dateTo" />
          <TextField source="debtAtEndOfPeriod" />
          <ShowButton label="Заплатить/Платежи" basePath="/invoices" />
      </Datagrid>
  </List>
);
const InvoicesXlsx = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 0,
      account: '',
    };
  }
  componentWillReceiveProps() {
    getDebtAtEndOfPeriod(this.props.record.rawFile).then((amount) => this.setState({ 
      amount, 
      account: getAccountFromXlsxFileName(this.props.record.rawFile.name) 
    }))
  }
  render() {
    if (this.state.account)
      return `№ счета: ${this.state.account} (Задолженность на конец периода: ${this.state.amount} руб.)`;
    else
      return `Ошибка в имени файла ${this.props.record.rawFile.name}`;
  };
}

const validateInvoice = (values, props) => {
  const errors = {};
  const dateFrom = new Date(values.dateFrom).getTime();
  const dateTo = new Date(values.dateTo).getTime();

  if (dateFrom >= dateTo)
    errors.dateTo = [props.translate('toDate is lowest then fromDate')];
  if (dateFrom === dateTo)
    errors.dateTo = [props.translate('toDate is equal fromDate')];

  return errors
}

export const InvoiceCreate = connect(null, { showNotification })(class extends React.Component {
  render() {
    let withoutProps = Object.assign({}, this.props)
    delete withoutProps.push;
    delete withoutProps.showNotification;

    return (
      <Create title="Пакетное добавление квитанций" undoable="false" {...withoutProps}>
          <SimpleForm redirect="list" validate={validateInvoice}>
              <DateInput source="dateFrom" validate={[required()]} />
              <DateInput source="dateTo" validate={[required()]} />
              <FileInput source="invoicesXlsx" validate={[required()]} multiple={true} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" maxSize={1000000} placeholder="Загрузить квитанции (xlsx, максимальный размер каждого файла: 1mb)">
                  <InvoicesXlsx />
              </FileInput>
          </SimpleForm>
      </Create>
    )
  }
});

const InvoiceShowAmount = ({ record, ...props }) => (
  <Typography variant="h4" gutterBottom={true} style={{ marginTop: 20, marginBottom: 40 }}>Задолженность на конец периода: {record.debtAtEndOfPeriod} рублей (№ {record.account})</Typography>
);

const InvoicePaymentForm = connect(null, { showNotification })(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amountPayment: String(this.props.record.debtAtEndOfPeriod),
    };
  }
  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start'}}>
          {this.props.record.enabledPayments && <MaterialTextField
            id="amountPayment"
            style={{ flexBasis: 200 }}
            variant="outlined"
            label="Сумма" 
            value={this.state.amountPayment}
            onChange={(e) => this.setState({ amountPayment: e.target.value })}
            placeholder={String(this.props.record.debtAtEndOfPeriod)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  ₽
                </InputAdornment>
              ),
            }}
          />}
          {this.props.record.enabledPayments && <Button color="primary" onClick={() => {
            if (this.state.amountPayment > this.props.record.debtAtEndOfPeriod)
              return this.props.showNotification('Your input value is greater then debt', 'warning');

            if (isNaN(this.state.amountPayment))
              return this.props.showNotification('Your input value is not number', 'warning');

            window.location = `/api/payments/paykeeper/payment?account=${this.props.record.account}&payAmount=${this.state.amountPayment}`;
          }}>Перейти на страницу оплаты *</Button>}
          {this.props.record.xlsxFile && <Button color="primary" href={this.props.record.xlsxFile.content} download={this.props.record.xlsxFile.fileName} title="Квитанция">Скачать квитанцию</Button>}
      </div>
    )
  }
});

const StatusField = translate(({ translate, record = {} }) => <TextField source="name" record={{ name: translate(`values.payments.status.${record.status}`) }}/>);

export const InvoiceShow = (props) => (
  <div>
      <Show {...props}>
          <SimpleShowLayout>
              <InvoiceShowAmount />
              <InvoicePaymentForm />
          </SimpleShowLayout>
      </Show>
      <img src={bankPng} style={{ marginTop: 20 }} alt="Логотипы банка"/>
      <Typography variant="body2" style={{ marginTop: 20, marginBottom: 10 }}>
          ТСЖ "Изумрудный", ИНН 5445010059, КПП 544501001
          р/с 407 038 105 004 300 00 447, 
          ФИЛИАЛ "Центральный" БАНКА ВТБ (ПАО) г. Москва,
          БИК: 044525411 к/с 301 018 101 452 500 00 411                       
          Телефон бухгалтерии 8(383-41)2-96-47.Показания по индивидуальным счетчикам просим передавать с 23 по 29 числа каждого месяца.
      </Typography>
      <Typography variant="body2" style={{ marginTop: 10, marginBottom: 10 }}>
          * Уважаемый собственник, в случае если вы ошибочно  оплатили  сумму больше, чем в платежной квитанции, вы можете заполнить заявление на возврат (образец прилагается) и  отправить в ТСЖ. После поступления от вас заявление, деньги будут возвращены в течении 5 дней. При оплате банковской картой, денежные средства возвращаются на ту же карту, с которой была произведена оплата.
      </Typography>
      <FileField record={{ url: refundApplication }} source="url" title="Образец заявления о возврате денег" />
      <Typography variant="h5" style={{ marginTop: 40, marginBottom: 10 }}>Платежи по данной квитанции:</Typography>
      <List actions={null} filterDefaultValues={{ invoice: props.id }} bulkActions={false} {...props} title=" " resource="payments" sort={{ field: 'createdAt', order: 'DESC' }} >
          <Datagrid>
              <ReferenceField 
                  source="payer" 
                  reference="users" 
                  linkType={false}>
                    <TextField source="fullName" />
              </ReferenceField>
              <TextField source="amount" />
              <StatusField source="status" />
              <DateField source="createdAt" showTime />
          </Datagrid>
      </List>
  </div>
)