import React from 'react';
import { Title, translate } from 'react-admin';
import tariffsPng from '../assets/tariffs.png';

const TariffsPage = ({ translate, ...props }) => (
    <div>
        <Title title={translate('staticPages.tariffs.name')} />

        <img src={tariffsPng} style={{ width: '100%' }} alt="Тарифы"/>
    </div>
);

export default translate(TariffsPage);