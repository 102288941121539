
const checkPermission = function(item, roles, record = null) {
  if (!roles)
    return false;

  if (roles.includes('superAdmin'))
    return true;

  if (roles.includes('houseManager'))
    switch (item) {
      case "postsList":
      case "postsShow":
      case "postsEdit":
      case "postsCreate":
        return true;

      case "postEdit":
        let userId = localStorage.getItem('id');
        if (userId === record.author) return true;

      case "feedbackList":
      case "feedbackEdit":
        return true;
    }

  if (roles.includes('appartamentOwner'))
    switch (item) {
      case "postsList":
      case "postsShow":
        return true;

      case "invoicesList":
      case "invoicesShow":
        return true;

      case "paymentsList":
      case "paymentsShow":
        return true;

      case "metersList":
      case "metersShow":
        return true;
    }

  // For anonymous and appartamentOwner.
  switch (item) {
    case "postsList":
    case "postsShow":
      return true;

    default:
      return null;
  }
};

export default checkPermission;