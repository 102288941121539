import XLSX from 'xlsx';

export const getDebtAtEndOfPeriod = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  reader.onload = () => {
    let workbook = XLSX.read(new Buffer(reader.result), { type: "array" });
    let worksheet = workbook.Sheets[workbook.SheetNames[0]];
    let desiredCell = worksheet['B1'];

    let desiredValue = (desiredCell ? desiredCell.v : undefined);
    resolve(desiredValue ? desiredValue.toFixed(2) : 0)
  };

  reader.onerror = reject;
});

export const getAccountFromXlsxFileName = name => {
  const account = Number(name.split('-').pop().split('.')[0]);
  if (account)
    return new Array(8 - String(account).length + 1).join('0') + account;
  else
    return null
};

export const convertFileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file.rawFile);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export const convertFileToBuffer = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file.rawFile);
  reader.onload = () => resolve(new Buffer(reader.result));
  reader.onerror = reject;
});