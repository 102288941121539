
import React from 'react';
import { Route } from 'react-router-dom';
import { WithPermissions, AppBar, Sidebar, Layout, fetchUtils, Admin, Resource, AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import createHistory from 'history/createBrowserHistory';
import { createMuiTheme } from '@material-ui/core/styles';
import JssProvider from 'react-jss/lib/JssProvider';

import russianMessages from 'ra-language-russian';
import englishMessages from 'ra-language-english';

import { PostShow, PostList, PostEdit, PostCreate, PostIcon } from './screens/PostsAdminScreen';
import { UserShow, UserList, ProfileEdit, UserEdit, UserCreate, UserIcon } from './screens/UsersAdminScreen';
import { MeterShow, MeterList, MeterEdit, MeterCreate, MeterIcon } from './screens/MetersAdminScreen';
import { InvoiceList, InvoiceShow, InvoiceCreate, InvoiceIcon } from './screens/InvoicesAdminScreen';
import { FeedbackEdit } from './screens/FeedbackPage';

import LoginPage from './screens/LoginPage';
import InDevelopPage from './screens/InDevelopPage';
import TariffsPage from './screens/TariffsPage';
import ContactsPage from './screens/ContactsPage';
import FeedbackPage from './screens/FeedbackPage';
import ReceptionsPage from './screens/ReceptionsPage';

import Menu from './Menu';
import checkPerm from './permissions';
import { getDebtAtEndOfPeriod, getAccountFromXlsxFileName, convertFileToBase64, convertFileToBuffer } from './actions';

import { apiUrl } from './config';

import './App.css';

const history = createHistory();

const authProvider = (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    return new Promise(function(resolve, reject) {
      fetchUtils.fetchJson(apiUrl + '/login', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          email: username,
          password: password
        })
      }).then((res) => res.json).then(function(res) {
        if (res.result) {
          localStorage.setItem('id', res.id);
          localStorage.setItem('roles', JSON.stringify(res.roles));
          localStorage.setItem('email', res.email);
          return resolve();
        }

        reject()
      }).catch(function(err) {
        reject();
      })
    });
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    return new Promise(function(resolve, reject) {
      fetchUtils.fetchJson(apiUrl + '/logout', {
        method: 'POST',
        credentials: 'include'
      }).then((res) => res.json).then(function(res) {
        if (res.result) {
          localStorage.removeItem('id');
          localStorage.removeItem('roles');
          localStorage.removeItem('email');
          return resolve();
        }

        reject();
      }).catch(function(err) {
        reject();
      })
    });
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const status  = params.status;
    if (status === 401 || status === 403) {
        return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    let roles = JSON.parse(localStorage.getItem('roles')) || ['anonymous'];
    return new Promise(function(resolve, reject) {
      fetchUtils.fetchJson(apiUrl + '/getRoles', {
        credentials: 'include',
      }).then((res) => res.json).then(function(res) {
        let match = res.roles.length === roles.length;
        if (match)
          roles.forEach(function(role) {
            if (!res.roles.includes(role))
              match = false;
          })
        if (match)
          return resolve();
        else   
          return reject();
      }).catch(function(err) {
        reject();
      })
    });
  }

  // called when the user is checked for permissions
  if (type === AUTH_GET_PERMISSIONS) {
    let roles = JSON.parse(localStorage.getItem('roles')) || ['anonymous'];
    return Promise.resolve(roles);
  }

  return Promise.reject('Unknown method');
}

const httpClient = (url, options = {}) => {
  options.credentials = 'include';
  return fetchUtils.fetchJson(url, options);
}

const MenuProfileEdit = ({...props}) => {
  let userId = localStorage.getItem('id');
  return (
    <WithPermissions
        location={props.location}
        render={({ permissions }) => 
          <ProfileEdit 
            id={userId}
            hasEdit={true}
            resource="users" 
            basePath="users"
            location={props.location}
            match={props.match}
            permissions={permissions} /> }
    />
  )
}

const MenuFeedback = ({...props}) => {
  let email = localStorage.getItem('email');
  return (
    <WithPermissions
        location={props.location}
        render={({ permissions }) => 
          <FeedbackPage
            page={props.page}
            userEmail={email}
            location={props.location}
            match={props.match}
            permissions={permissions} /> }
    />
  )
}


const CustomRoutes = [
  <Route exact path="/profile" component={MenuProfileEdit} />,
  <Route exact path="/tariffs" component={TariffsPage} />,
  <Route exact path="/contacts" component={ContactsPage} />,
  <Route exact path="/receptions" component={ReceptionsPage} />,
  <Route exact path="/openinformations" component={InDevelopPage} />,
  <Route exact path="/webcams" component={InDevelopPage} />,
  <Route exact path="/feedback/faq" component={({...props}) => <MenuFeedback {...props} page={'faq'} />} />,
  <Route exact path="/feedback/create" component={({...props}) => <MenuFeedback {...props} page={'create'} />} />,
  <Route exact path="/feedback/moderate" component={({...props}) => <MenuFeedback {...props} page={'moderate'} />} />,
  <Route exact path="/pools" component={InDevelopPage} />,
]

const MyAppBar = (props) => (
  <AppBar {...props} logout={null} />
);
const MySidebar = props => <Sidebar {...props} size={260} />;

const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} menu={Menu} sidebar={MySidebar} />;

const customMessages = {
  ru: {
    "Unauthorized": "Доступ запрещен",
    "Internal Server Error": "Внутренняя ошибка сервера",
    "Not Implemented": "Не выполненно",
    "Not Found": "Ресурс не найден",
    "Error: Roles are not loaded from server": "Ошибка: Роли не были загружены с сервера",
    "Your input value is greater then debt": "Вы ввели сумму, превышающую сумму задолженности",
    "Your input value is not number": "Пожалуйста введите число",
    "Error: AccessTypes are not loaded from server": "Ошибка: Типы доступа не были загружены с сервера",
    "Payload Too Large": "Слишком большой размер (>10mb)",
    "Error: Statuses are not loaded from server": "Ошибка: Статусы не были загружены с сервера",
    "Error: Categories are not loaded from server": "Ошибка: Категории не были загружены с сервера",
    "toDate is lowest then fromDate": "Конец периода не может быть меньше начала периода",
    "toDate is equal fromDate": "Конец и начало периода не могут быть равны",
    "Your metersData not accepted": "Ваши показания не приняты сервером",
    "Error: bad params or frequently": "Неверные параметры или вы слишком часто отправляете показания (попробуйте повторить через 5 минут)",
    "Error: previous value can not be greater than this value": "Новые показания не могут быть меньше предыдущих показаний",
    "Обязательно для заполнения": "Обязательно для заполнения", // TODO
    values: {
      users: {
        roles: {
          "superAdmin": "Супер пользователь",
          "houseManager": "Главный по дому",
          "appartamentOwner": "Владелец квартиры"
        }
      },
      posts: {
        status: {
          "sketch": "Черновик",
          "publish": "Опубликовано",
          "archive": "Архив",
        },
        accessType: {
          "house": "Только дом",
          "commune": "ТСЖ",
          "all": "Все (включая анонимов)",
        }
      },
      feedback: {
        category: {
          "suggestion": "Предложение",
          "complaint": "Жалоба",
          "thanks": "Благодарность",
        },
        status: {
          "waiting": "Ожидает рассмотрения",
          "completed": "Обработано",
          "spam": "Спам",
        }
      },
      payments: {
        status: {
          "successfully": "Успешно выполнена",
        }
      }
    },
    staticPages: {
      tariffs: {
        name: 'Тарифы'
      },
      contacts: {
        name: 'Контакты'
      },
      receptions: {
        name: 'Часы приема'
      },
      openInformations: {
        name: 'Раскрытие информации'
      },
      webcams: {
        name: 'Вебкамеры'
      },
      feedback: {
        faq: {
          name: 'Обратная связь'
        },
        create: {
          name: 'Оставить обратную связь'
        },
        moderate: {
          name: 'Работа с обратной связью'
        } 
      },
      pools: {
        name: 'Голосования'
      },
      inDevelopPage: {
        name: 'Страница в разработке'
      }
    },
    resources: {
      users: {
        name: 'Пользователь |||| Пользователи',
        fields: {
          email: "Эл. почта",
          houseNumber: "Номер дома",
          appartamentNumber: "Номер помещения",
          fullName: "ФИО",
          roles: "Роли",
          accounts: "Лицевые счета",
          'accounts._id': 'Лицевой счет',
          password: "Пароль",
          currentPassword: "Текущий пароль",
          repeatPassword: "Повторите новый пароль"
        }
      },
      meters: {
        name: 'Счетчик |||| Счетчики',
        fields: {
          lastValue: 'Последние показания',
          meterId: 'Номер счетчика',
          name: 'Наименование',
          account: "Лицевой счет",
        }
      },
      metersData: {
        name: 'Показание |||| Показания',
        fields: {
          value: 'Показания',
          createdAt: "Дата",
          user: 'Внес показания'
        }
      },
      posts: {
        name: 'Новость |||| Новости',
        fields: {
          title: "Заголовок",
          teaser: "Краткое описание",
          frontImage: "Картинка",
          fullText: "Текст",
          status: "Статус",
          sticked: 'Закрепить новость',
          accessType: "Область видимости",
          tags: "Теги",
          publishedAt: "Дата публикации",
          author: "Автор",
          houseNumber: "Номер дома"
        }
      },
      feedback: {
        fields: {
          title: 'Заголовок',
          houseNumber: 'Номер дома',
          appartamentNumber: 'Номер помещения',
          email: 'Почтовый адрес',
          category: 'Категория',
          text: 'Текст обратной связи',
          status: 'Статус',
          createdAt: 'Дата и время',
          moderateActions: 'Действия модераторов',
        }
      },
      invoices: {
        name: 'Платежная квитанция |||| Платежные квитанции',
        fields: {
          account: "Лицевой счет",
          debtAtEndOfPeriod: "Задолженность на конец периода",
          dateFrom: "Период (С)",
          dateTo: "Период (До)",
          invoicesXlsx: "Квитанции (расширение файлов: xlsx)"
        }
      },
      payments: {
        name: 'Платеж |||| Платежи',
        fields: {
          payer: "Плательщик",
          amount: "Заплаченная сумма",
          status: "Статус операции",
          createdAt: "Дата",
        }
      },
    }
  },
  en: {
    "Unauthorized": "Unauthorized",
    "Internal Server Error": "Internal Server Error",
    "Not Implemented": "Not Implemented",
    "Not Found": "Not Found",
    resources: {
      users: {
        name: 'User |||| Users',
        fields: {
          email: "Email",
          houseNumber: "House number",
          appartamentNumber: "Appartament number",
          fullName: "Full name",
          roles: "Roles",
          password: "Password"
        }
      }
    }
  }
}

const messages = {
  ru: { ...russianMessages, ...customMessages.ru },
  en: { ...englishMessages, ...customMessages.en }
};
const i18nProvider = locale => messages[locale];

const initialState = {
  admin: {
    auth: {
      isLoggedIn: localStorage.getItem('roles') ? true : false,
    }
  }
}

const uploadImageFeature = requestHandler => (type, resource, params) => {
  if ((type === 'CREATE' || type === 'UPDATE') && resource === 'posts') {
    if (params.data.frontImage && params.data.frontImage.rawFile instanceof File) {
      
      return convertFileToBase64(params.data.frontImage)
        .then(base64Picture => ({
          data: base64Picture,
          title: params.data.frontImage.title,
        }))
        .then(transformedPicture => requestHandler(type, resource, {
          ...params,
          data: {
            ...params.data,
            frontImage: transformedPicture,
          },
        }));
    }
  }
  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};


const uploadInvoicesXlsxFeature = requestHandler => (type, resource, params) => {
  if ((type === 'CREATE') && resource === 'invoices' && params.data.invoicesXlsx) {
    let promises = [];
    for (let xlsx of params.data.invoicesXlsx) {
      if (xlsx.rawFile instanceof File)
        promises.push(convertFileToBuffer(xlsx).then((base64File) => {
          return getDebtAtEndOfPeriod(xlsx.rawFile).then((remainAmount) => {
            const account = getAccountFromXlsxFileName(xlsx.rawFile.name);
            if (account)
              return {
                data: base64File,
                debtAtEndOfPeriod: remainAmount,
                account
              };
          })
        }))
    }

    return Promise.all(promises).then(dataObjects => requestHandler(type, resource, {
      ...params,
      data: {
        ...params.data,
        invoicesXlsx: dataObjects,
      },
    }))
  }

  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

const theme = createMuiTheme({
  palette: {
    primary: {
      //main: '#007bb2'
      main: '#3a7b5f'
    },
    secondary: {
      main: '#006539',
      //main: '#2196f3',
    },
  },
  typography: {
    useNextVariants: true,
  },
});


const escapeRegex = /([[\].#*$><+~=|^:(),"'`\s])/g;
let classCounter = 0;
// Heavily inspired of Material UI:
// @see https://github.com/mui-org/material-ui/blob/9cf73828e523451de456ba3dbf2ab15f87cf8504/src/styles/createGenerateClassName.js
// The issue with the MUI function is that is create a new index for each
// new `withStyles`, so we handle have to write our own counter
export const generateClassName = (rule, styleSheet) => {
    classCounter += 1;

    if (process.env.NODE_ENV === 'production') {
        return `c${classCounter}`;
    }

    if (styleSheet && styleSheet.options.classNamePrefix) {
        let prefix = styleSheet.options.classNamePrefix;
        // Sanitize the string as will be used to prefix the generated class name.
        prefix = prefix.replace(escapeRegex, '-');

        if (prefix.match(/^Mui/)) {
            return `${prefix}-${rule.key}`;
        }

        return `${prefix}-${rule.key}-${classCounter}`;
    }

    return `${rule.key}-${classCounter}`;
};

const App = () => (
    <JssProvider generateClassName={generateClassName}>
        <Admin
          title="ТСЖ Изумрудный"
          locale="ru" i18nProvider={i18nProvider}
          loginPage={LoginPage}
          appLayout={MyLayout} 
          customRoutes={CustomRoutes} 
          history={history} 
          dataProvider={uploadInvoicesXlsxFeature(uploadImageFeature(simpleRestProvider(apiUrl, httpClient)))} 
          authProvider={authProvider}
          initialState={initialState}
          theme={theme}>

          {roles => [   
            <Resource 
              name="posts" 
              show={checkPerm('postsShow', roles) && PostShow} 
              list={checkPerm('postsList', roles) && PostList} 
              edit={checkPerm('postsEdit', roles) && PostEdit} 
              create={checkPerm('postsCreate', roles) && PostCreate} 
              icon={PostIcon}/>,

            <Resource 
              name="users" 
              show={checkPerm('usersShow', roles) && UserShow} 
              list={checkPerm('usersList', roles) && UserList} 
              edit={checkPerm('usersEdit', roles) && UserEdit} 
              create={checkPerm('usersCreate', roles) && UserCreate} 
              icon={UserIcon}/>,

            <Resource 
              name="meters" 
              show={checkPerm('metersShow', roles) && MeterShow} 
              list={checkPerm('metersList', roles) && MeterList} 
              edit={checkPerm('metersEdit', roles) && MeterEdit} 
              create={checkPerm('metersCreate', roles) && MeterCreate} 
              icon={MeterIcon}/>,

            <Resource 
              name="feedback" 
              edit={checkPerm('feedbackEdit', roles) && FeedbackEdit} 
              create={
                // This hack for visible create form.
                checkPerm('feedbackCreate', roles) && ''
              } />,

            <Resource 
              name="invoices"
              create={checkPerm('invoicesCreate', roles) && InvoiceCreate}
              list={checkPerm('invoicesList', roles) && InvoiceList}
              show={checkPerm('invoicesShow', roles) && InvoiceShow} 
              icon={InvoiceIcon} />,

              
            <Resource
              // Only for invoices show page.
              name="payments" />,
            <Resource
              // Only for meters show page.
              name="metersData" />,

            <div></div>
          ]}
        </Admin>
    </JssProvider>
);

export default App;