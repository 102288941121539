import React from 'react';
import { Toolbar, SaveButton, DeleteButton, DisabledInput, Filter, ArrayField, SingleFieldList, ChipField, ArrayInput, SimpleFormIterator, List, Datagrid, Edit, Create, SimpleForm, TextField, ShowButton, EditButton, TextInput } from 'react-admin';
import { fetchUtils, SelectArrayInput, Show, SimpleShowLayout, translate, showNotification } from 'react-admin';
import { connect } from 'react-redux';
import { required, email, maxLength, minLength } from 'react-admin';
import GroupIcon from '@material-ui/icons/Group';

import { apiUrl } from '../config';

export const UserIcon = GroupIcon;

const UserFilter = (props) => (
  <Filter {...props}>
      <TextInput source="email" alwaysOn />
      <TextInput source="houseNumber" alwaysOn />
      <TextInput source="appartamentNumber" alwaysOn />
      <TextInput source="accounts._id" alwaysOn />
  </Filter>
);

const RolesChipField = translate(({ translate, record = {} }) => <ChipField source="name" record={{ name: translate(`values.users.roles.${record}`) }}/>);

export const UserList = (props) => (
    <List bulkActions={false} filters={<UserFilter />} {...props}>
        <Datagrid>
            <TextField source="email" />
            <TextField source="houseNumber" />
            <TextField source="appartamentNumber" />
            <TextField source="fullName" />
            <ArrayField source="roles">
                <SingleFieldList linkType={false}>
                    <RolesChipField />
                </SingleFieldList>
            </ArrayField>
            <EditButton basePath="/users" />
            <ShowButton basePath="/users" />
        </Datagrid>
    </List>
);

const UserTitle = translate(({ record, translate }) => {
  return <span>{translate('resources.users.name', { smart_count: 1 })} {record ? `${record.email}` : ''}</span>;
});

const validateUserEditForm = (values, props) => {
  const errors = {};
  // Because default reuired() method is not work for edit form. Only for create.
  if (!values.roles || values.roles.length === 0)
    errors.roles = [props.translate('ra.validation.required')];

  if (values.password && !values.currentPassword)
    errors.currentPassword = [props.translate('ra.validation.required')];

  if (values.password && !values.repeatPassword)
    errors.repeatPassword = [props.translate('ra.validation.required')];
  else if (values.password && values.password !== values.repeatPassword)
    errors.repeatPassword = [props.translate('ra.validation.required')];
 
  return errors
};

const UserEditToolbar = ({...props}) => (
  <Toolbar {...props}>
      <SaveButton />
      <DeleteButton resource="users" record={props.record} undoable={false} />
  </Toolbar>
)

export const UserEdit = connect(null, { showNotification })(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      roles: [],
      redirect: 'list'
    };
  }
  componentDidMount() {
    let obj = this;
    let { showNotification } = this.props;
    fetchUtils.fetchJson(apiUrl + '/users/roles', {
      method: 'GET',
      credentials: 'include',
    }).then((res) => res.json).then(function(roles) {
      obj.setState({ roles: roles.map((role) => ({ id: role, name: `values.users.roles.${role}` })) });
    }).catch(err => { showNotification('Error: Roles are not loaded from server', 'warning') });
  }
  render() {
    let withoutProps = Object.assign({}, this.props)
    delete withoutProps.showNotification;

    return (
      <Edit title={<UserTitle />} undoable={false} {...withoutProps} >
          <SimpleForm submitOnEnter={false} toolbar={<UserEditToolbar />} redirect={this.state.redirect} validate={validateUserEditForm}>
              <TextInput source="email" type="email" validate={[required(), email()]}/>
              <TextInput source="password" type="password" />
              <TextInput source="repeatPassword" type="password" />
              <SelectArrayInput 
                source="roles" 
                choices={this.state.roles} 
                validate={[required()]}/>

              <ArrayInput source="accounts">
                  <SimpleFormIterator>
                      <TextInput source="_id" label="Номер счета" validate={[minLength(8), maxLength(8)]}/>
                  </SimpleFormIterator>
              </ArrayInput>
              <TextInput source="fullName" />
              <TextInput source="houseNumber" validate={[required()]}/>
              <TextInput source="appartamentNumber" />
          </SimpleForm>
      </Edit>
    )
  }
});

const ProfileEditToolbar = ({...props}) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
)

export const ProfileEdit = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      redirect: 'posts'
    };
  }
  render() {
    return (
      <Edit title={<UserTitle />} undoable={false} {...this.props} >
          <SimpleForm 
              submitOnEnter={false} 
              redirect={this.state.redirect} 
              validate={validateUserEditForm} 
              toolbar={<ProfileEditToolbar />}>
              <DisabledInput source="email" />
              <TextInput source="currentPassword" type="password" />
              <TextInput source="password" type="password" />
              <TextInput source="repeatPassword" type="password" />
              <TextInput source="fullName" />
              <DisabledInput source="houseNumber" />
              <DisabledInput source="appartamentNumber" />
              <ArrayField source="accounts">
                  <SingleFieldList linkType={false}>
                      <ChipField source="_id" />
                  </SingleFieldList>
              </ArrayField>
          </SimpleForm>
      </Edit>
    )
  }
};


export const UserCreate = connect(null, { showNotification })(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { roles: [] };
  }
  componentDidMount() {
    let obj = this;
    let { showNotification } = this.props;

    fetchUtils.fetchJson(apiUrl + '/users/roles', {
      method: 'GET',
      credentials: 'include',
    }).then((res) => res.json).then(function(roles) {
      obj.setState({ roles: roles.map((role) => ({ id: role, name: `values.users.roles.${role}` })) });
    }).catch(err => { showNotification('Error: Roles are not loaded from server', 'warning') });
  }
  render() {
    let withoutProps = Object.assign({}, this.props)
    delete withoutProps.showNotification;

    return (
      <Create title="Создание пользователя" undoable="false" {...withoutProps}>
          <SimpleForm redirect="list">
              <TextInput source="email" type="email" validate={[required(), email()]}/>
              <TextInput source="password" type="password" validate={[required()]}/>
              <SelectArrayInput source="roles" choices={this.state.roles} validate={[required()]}/>
              <ArrayInput source="accounts">
                  <SimpleFormIterator>
                      <TextInput source="_id" label="Номер счета" validate={[minLength(8), maxLength(8)]}/>
                  </SimpleFormIterator>
              </ArrayInput>
              <TextInput source="fullName" />
              <TextInput source="houseNumber" validate={[required()]}/>
              <TextInput source="appartamentNumber" />
          </SimpleForm>
      </Create>
    )
  }
});

export const UserShow = (props) => (
    <Show title={<UserTitle />} {...props}>
        <SimpleShowLayout>
            <TextField source="email" />
            <TextField source="fullName" />
            <TextField source="houseNumber" />
            <TextField source="appartamentNumber" />
            <ArrayField source="roles">
                <SingleFieldList linkType={false}>
                    <RolesChipField />
                </SingleFieldList>
            </ArrayField>
            <ArrayField source="accounts">
                <SingleFieldList linkType={false}>
                    <ChipField source="_id" />
                </SingleFieldList>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);