import React from 'react';
import { Title, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    padding: 10,
  },
  text: {
    fontSize: 24
  }
};

const ReceptionsPage = ({ translate, classes, ...props }) => (
    <div className={classes.container}>
        <Title title={translate('staticPages.receptions.name')} />
        <p className={classes.text}><b>Часы приема платежей:</b><br />Вторник-пятница с 09:00 до 17:00.<br />Обед с 12:00 до 13:00<br />2-ая суббота месяца с 15:30 до 17:30.<br />Воскресенье – выходной.</p>
        <p className={classes.text}><b>График работы паспортиста:</b><br />Среда  с 16:30 до 19:30,<br />Пятница с 17:00 до 18:00.</p>
        <p className={classes.text}>В случае аварийных ситуаций в выходные и праздничные днизвонить управляющему или главному инженеру.</p>
    </div>
);

export default translate(withStyles(styles)(ReceptionsPage));